<template>
  <div>
    <div class="container property__holder">
      <div class="row">
        <div class="col-md-6">
          <div class="prop1">
            <h3></h3>
            <div>
              <!-- <p>{{property.price}}</p> -->
              <img alt="" />
              <small></small>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="prop2">
            <!-- <img src="../assets/images/point-map.svg" alt="" /> -->

            <h3></h3>
            <div>
              <small></small>
            </div>
            <!-- <small>{{property.city}}, {{property.state}}</small> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Image thumbnail -->
    <div class="container propertyimage_holder">
      <div class="row">
        <div v-if="!loader" class="col-md-8 col-lg-8 image-holder">
          <div class="main__img">
            <div class="imgvid">
              <span class="photo_span"></span>
              <!-- <span class="video_span"><img src="../assets/images/video-icon.svg" alt=""> Video</span> -->
            </div>
            <!-- <img :src="property.oneimageurl" alt="" /> -->
            <img class="bigimage" alt="" />
          </div>
          <div class="thumbnail-cover">
            <div class="thumbnail-wrap">
              <div class="thumbnail__img first__thumbnail">
                <div class="thumb-wrap">
                  <img alt="" />
                  <img alt="" />
                  <img alt="" class="d-none d-md-block d-lg-block" />
                  <img alt="" class="d-none d-md-block d-lg-block" />
                  <img alt="" class="d-none d-md-block d-lg-block" />

                  <p></p>
                </div>
                <!-- <img class=""  alt="" />
                  <img class=""  alt="" />
                  <img class="" alt="" />
                  <img class="" alt="" /> -->
              </div>
              <!-- <p>Bedroom</p> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4 col-lg-4">
              <div class="thumbnail-wrap">
                <div class="thumbnail__img first__thumbnail">
                  <img src="../assets/images/prop2.svg" alt="" />
                </div>
                <div class="thumbnail__img second__thumbnail">
                  <img class="" src="../assets/images/prop2.svg" alt="" />
                </div>
              </div>
            </div> -->
      </div>
    </div>
    <!-- Image Thumbnail -->
  </div>
</template>

<script>
export default {
  name: "SkeletonDetails",
  components: {},
  data() {
    return {
      loader: false
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;

.property__holder {
  padding-top: 10rem;
}
.propertyimage_holder {
  padding-bottom: 2rem;
}

.prop1 {
  text-align: left;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: $black;
    margin-bottom: 0;
    width: 100%;
    height: 41px;
    animation: pulse-bg 1s infinite;
  }
  div {
    margin-top: 10px;
    width: 100%;
    height: 20px;
    animation: pulse-bg 1s infinite;
    img {
      padding-right: 0.5rem;
      padding-left: 0.2rem;
    }
    small {
      width: 100%;
      height: 20px;
      animation: pulse-bg 1s infinite;
    }
  }
}

// Proprty top details
.prop2 {
  display: flex;
  justify-content: right;
  text-align: right;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 32px;
    color: $black;
    margin-bottom: 0;
    width: 50%;
    height: 41px;
    animation: pulse-bg 1s infinite;
  }
  div {
    small {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 50px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  // p {
  //   font-family: Gotham;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 20px;
  //   line-height: 50px;
  //   color: rgba(0, 0, 0, 0.5);
  // }
  // div {
  //   display: flex;
  //   img {
  //     padding-right: 0.7rem;
  //   }
  // }
}
// Proprty top details ends

// Image thumbnail
.main__img {
  position: relative;
  text-align: left;
  .imgvid {
    position: relative;
    .photo_span {
      cursor: pointer;
      // background: rgba(235, 0, 51, 0.97);

      line-height: 33px;
      padding: 5px 40px;
      border-radius: 10px 10px 0 0;
      //   font-size: 15px;
      //   margin-right: 10px;
      width: 100%;
      height: 35px;
      animation: pulse-bg 1s infinite;
    }
    .video_span {
      cursor: pointer;
      // background: rgba(235, 0, 51, 0.97);
      color: $black;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 33px;
      padding: 5px 10px;
      border-radius: 10px 10px 0 0;
      font-size: 15px;
      // margin-right: 10px;
    }
  }
  .spanDive {
    background: rgba(235, 0, 51, 0.97) !important;
    color: $secondary !important;
  }
  .bigimage {
    width: 100%;
    max-width: 726px;
    height: auto;
    max-height: 422px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2px;
    // cursor: pointer;
    // padding: 5px;
    // border: 1px solid #ddd;
    // height: 100%;
    object-fit: cover;
    width: 100%;
    height: 500px;
    animation: pulse-bg 1s infinite;
  }
}
.videoview {
  width: 100%;
  max-width: 726px;
  height: auto;
  max-height: 422px;
  // display: none;
}
.thumb-wrap {
  display: flex;
  // position: relative;

  //   img {
  //       display: flex;

  //   }
}
.loader-div {
  text-align: center;
}
.first__thumbnail {
  // padding-top: 3.0rem;
  margin-bottom: 15px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.first__thumbnail::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.thumbnail__img {
  // display: flex;
  // justify-content: flex-start;
  // position: relative;
  img:first-child {
    margin-left: 0;
  }
  img {
    width: 100%;
    min-width: 150px;
    margin: 3px;
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px; /* Rounded border */
    padding: 2px; /* Some padding */
    height: 100%;
    max-height: 100px;
    cursor: pointer;
    object-fit: cover;
    // height: 212px;
    height: 100px;
    animation: pulse-bg 1s infinite;
  }
  img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
.activeSmallImage {
  border: 2px solid $primary !important;
}
// Image thumbnail

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #e6e6e6;
  }
  100% {
    background-color: #eee;
  }
}
@keyframes shine-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #c0bdbd;
  }
  100% {
    background-color: #eee;
  }
}

@media only screen and (max-width: 599px) {
  .main__img {
    .bigimage {
      max-height: 220px;
    }
  }
  .prop2 {
    justify-content: left;
    text-align: left;
  }
  .thumbnail__img {
    img {
      min-width: 162px;
    }
  }
}
</style>
