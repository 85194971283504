<template>
  <div class="king">
    <div class="super">
      <div class="holder">
        <Header />
        <SkeletonDetails v-if="loader" />
        <div v-if="!loader" class="container property__holder">
          <div class="row">
            <div class="col-md-8">
              <div class="prop1">
                <h3>{{ property.title }}</h3>
                <div>
                  <!-- <p>{{property.price}}</p> -->
                  <img src="../assets/images/point-map.svg" alt="" />
                  <small>{{ property.city }}, {{ property.state }}</small>
                  <p>PIN: {{ property.pin }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="prop2">
                <!-- <img src="../assets/images/point-map.svg" alt="" /> -->
                <h3 v-if="!property.price">&#8358;0</h3>
                <h3 v-else>
                  &#8358;{{ Number(property.price).toLocaleString() }}
                </h3>
                <div v-if="property.businesstypename != 'Sale'">
                  <small>Per annum</small>
                </div>
                <!-- <small>{{property.city}}, {{property.state}}</small> -->
              </div>
            </div>
          </div>
        </div>

        <!-- Image thumbnail -->
        <div class="container propertyimage_holder">
          <!-- <div v-if="loader" class="loader-div">
               <i class="fa fa-spinner fa-spin fa-2x  ml-3 f-12  btn-loader">
                </i>
            </div> -->

          <div class="row">
            <div v-if="!loader" class="col-md-8 col-lg-8 image-holder">
              <div class="main__img">
                <div class="imgvid">
                  <span
                    @click="switchView('photo')"
                    :class="{ spanDive: whichtoview ? true : false }"
                    class="photo_span"
                    ><img src="../assets/images/photo-icon.svg" alt="" />
                    Photos</span
                  >
                  <span
                    @click="switchView('video')"
                    :class="{ spanDive: whichtoview ? false : true }"
                    class="video_span"
                    ><img src="../assets/images/video-icon.svg" alt="" />
                    Video</span
                  >
                </div>
                <!-- <img :src="property.oneimageurl" alt="" /> -->
                <img
                  v-if="whichtoview"
                  class="bigimage"
                  :src="property.oneimageurl.url"
                  width="100%"
                  height="400px"
                  @load="onImgLoad"
                  alt=""
                />
                <video v-if="!whichtoview" class="videoview" controls>
                  <source src="movie.mp4" type="video/mp4" />
                  <source src="movie.ogg" type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div v-if="whichtoview" class="thumbnail-cover">
                <div class="thumbnail-wrap">
                  <div class="thumbnail__img first__thumbnail">
                    <div
                      class="thumb-wrap"
                      v-for="(image, index) in images"
                      :key="index"
                    >
                      <img
                        @click="activeImage(index)"
                        :class="{
                          activeSmallImage:
                            index === indexOfActive ? true : false
                        }"
                        :src="image.url"
                        alt=""
                      />
                      <p>{{ image.title }}</p>
                    </div>
                    <!-- <img class="" src="../assets/images/prop2.svg" alt="" />
                  <img class="" src="../assets/images/prop2.svg" alt="" />
                  <img class="" src="../assets/images/prop2.svg" alt="" />
                  <img class="" src="../assets/images/prop2.svg" alt="" /> -->
                  </div>
                  <!-- <p>Bedroom</p> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-md-4 col-lg-4">
              <div class="thumbnail-wrap">
                <div class="thumbnail__img first__thumbnail">
                  <img src="../assets/images/prop2.svg" alt="" />
                </div>
                <div class="thumbnail__img second__thumbnail">
                  <img class="" src="../assets/images/prop2.svg" alt="" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- Image Thumbnail -->

        <!-- Overview__navigation -->
        <div class="container overviewbtn">
          <div class="overviewbtn__navigation">
            <button class="btn over" @click="scroll(1)">Overview</button>
            <button class="btn fame" @click="scroll(2)">
              Features & Amenities
            </button>
            <button class="btn nb" @click="scroll(3)">Nearby Places</button>
            <button class="btn sp" @click="scroll(4)">
              Similar properties
            </button>
          </div>
        </div>
        <!-- Overview__navigation -->

        <!-- Overview -->
        <div id="overview2" class="overview">
          <div class="container overview__holder">
            <div class="row">
              <!-- col-md-6 col-lg-4 col-sm-12 col-xl-4 -->
              <div class="col-md-8 col-lg-8" ref="over-view">
                <h5 class="overview__text">Overview</h5>
                <div class="overview__body">
                  <div class="facility__holder">
                    <div class="facility__wrap">
                      <img src="../assets/images/bed.svg" alt="" />
                      <p>{{ property.bedrooms }} Bedrooms</p>
                    </div>
                    <div class="facility__wrap">
                      <img src="../assets/images/bathroom.svg" alt="" />
                      <p>{{ property.bathrooms }} Bathrooms</p>
                    </div>
                    <div class="facility__wrap">
                      <img src="../assets/images/toilet.svg" alt="" />
                      <p>{{ property.toilets }} Toilets</p>
                    </div>
                    <div class="facility__wrap">
                      <img src="../assets/images/parking.svg" alt="" />
                      <p>{{ property.parking }} Parking</p>
                    </div>
                  </div>
                  <p class="facility__description">
                    {{ property.description }}
                  </p>
                </div>

                <!-- Features and Amenities -->
                <h6 id="features" class="amenities__text">
                  Features & Amenities
                </h6>
                <div class="features">
                  <div class="features__amenities">
                    <p v-for="(item, index) in facilities" :key="index">
                      <span></span>{{ item }}
                    </p>
                  </div>
                </div>
                <!-- Features and Amenities -->
              </div>

              <div class="col-md-4 col-lg-4 col-sm-12 col-xl-4">
                <div class="interested__property">
                  <p>Interested in this property?</p>
                  <div class="interested_button">
                    <div class="rent_now">
                      <button
                        @click="moveTo(property.pin)"
                        class="btn btn-block"
                      >
                        Rent Now
                      </button>
                    </div>
                    <div class="schedule__tour">
                      <button class="btn btn-block" @click="goToTour(property)">
                        Schedule a tour
                      </button>
                    </div>
                  </div>
                  <!-- <div class="book__radiobtn d-none">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio1"
                        name="customRadio"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="customRadio1"
                        >Book a free pre-scheduled tour</label
                      >
                    </div>
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio2"
                        name="customRadio"
                        class="custom-control-input"
                      />
                      <label class="custom-control-label" for="customRadio2"
                        >Book a paid visit at your convenience</label
                      >
                    </div>

                    <form class="form__details">
                      <div class="form__input">
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Your Name"
                        />

                        <input
                          class="form-control"
                          type="text"
                          placeholder="Your Email"
                        />

                        <input
                          class="form-control"
                          type="text"
                          placeholder="Phone Number"
                        />
                      </div>
                      <div class="form__datetime">
                        <input
                          class="form-control"
                          type="date"
                          name="date"
                          placeholder="Phone Number"
                        />
                        <input
                          class="form-control"
                          type="time"
                          name="date"
                          placeholder="Phone Number"
                        />

                        <textarea
                          name="message"
                          id=""
                          cols=""
                          rows=""
                          placeholder="Want to drop a message"
                        ></textarea>
                      </div>
                    </form>

                    <div class="interestedBtn">
                      <button class="btn">Submit</button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Overview -->

        <!-- Similar properties -->
        <div class="similar" id="similar" v-if="nearbyprops.length === 0">
          <h3>Similar Properties</h3>
          <p class="no_property" v-if="nearbyprops.length === 0">
            No property near your address
          </p>
          <div class="property-list" v-else>
            <div class="container-fluid">
              <div class="row">
                <div
                  class="col-md-4 col-lg-4"
                  v-for="(property, index) in nearbyprops"
                  :key="index"
                >
                  <div class="list-grid">
                    <div class="list-img">
                      <div class="for-rent">
                        <p>{{ property.businesstypename }}</p>
                      </div>
                      <div
                        id="carouselExampleControls"
                        class="carousel slide"
                        data-ride="carousel"
                      >
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img
                              @click="fetchProperty2(property.pin)"
                              class="d-block w-100"
                              :src="property.oneimageurl"
                              alt="First slide"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="property-details-holder">
                        <div class="card property-details-wrap">
                          <div class="card-body">
                            <div class="details-header">
                              <p class="card-title">{{ property.title }}</p>
                              <p><span>PIN:</span> {{ property.pin }}</p>
                            </div>
                            <div class="location-holder">
                              <img
                                src="../assets/images/map-point.svg"
                                alt=""
                              />
                              <small
                                >{{ property.city }},
                                {{ property.state }}</small
                              >
                            </div>
                            <p class="details-text">
                              {{ property.description }}
                            </p>
                            <h2 class="property-amount">
                              &#8358;
                              {{ Number(property.price).toLocaleString() }}
                              <span class="duration">Per annum</span>
                            </h2>
                            <div class="property-option container">
                              <div class="facility-holder">
                                <div class="facility-wrap">
                                  <img src="../assets/images/bed.svg" alt="" />
                                  <p>{{ property.bedrooms }} bedroom</p>
                                </div>
                                <div class="facility-wrap">
                                  <img
                                    src="../assets/images/bathroom.svg"
                                    alt=""
                                  />
                                  <p>{{ property.bathrooms }} bathrooms</p>
                                </div>
                                <div class="facility-wrap">
                                  <img
                                    src="../assets/images/toilet.svg"
                                    alt=""
                                  />
                                  <p>{{ property.toilets }} toilets</p>
                                </div>
                                <div class="facility-wrap">
                                  <img
                                    src="../assets/images/parking.svg"
                                    alt=""
                                  />
                                  <p>{{ property.parking }} parking</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="view-more">
            <button class="btn">
              <router-link class="more_props" to="/propertylist">
                VIEW ALL LISTING
              </router-link>
            </button>
          </div> -->
        </div>
        <!-- Similar properties -->
      </div>
    </div>
    <!-- Footer-starts -->
    <FooterMain />
    <!-- Footer-ends -->
  </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
import SkeletonDetails from "../components/SkeletonDetails.vue";
import Header from "../views/Header";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "PropertyDetails",
  components: {
    Header,
    FooterMain,
    SkeletonDetails
  },
  data() {
    return {
      property: {
        oneimageurl: {
          url: ""
        }
      },
      // property: '',
      images: [],
      indexOfActive: 0,
      facilities: [],
      userId: "",
      profileData: "",
      nearbyprops: [],
      whichtoview: true,
      timer: null,
      loader: false,
      isLoaded: false
      // scrollPosition: null,
      // isActive: false
    };
  },
  mounted() {
    this.fetchProperty();
    this.nearByProperty();
    this.startRotation();
    this.userId = JSON.parse(localStorage.getItem("shelta-user")).id;

    // this.getUserProfile(this.userId);
    // window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    goToTour(propertyData) {
      Api.pushPropertyDetails(propertyData);
      this.$router.push({ path: `/tour` });
    },
    onImgLoad() {
      this.isLoaded = true;
    },
    switchView: function(event) {
      if (event === "video") {
        this.whichtoview = false;
      } else if (event === "photo") {
        this.whichtoview = true;
      } else {
        this.whichtoview = true;
      }
    },
    activeImage: function(index) {
      this.property.oneimageurl = this.images[index];
      this.indexOfActive = index;
    },
    startRotation: function() {
      this.timer = setInterval(this.next, 3000);
    },
    next: function() {
      this.indexOfActive += 1;
      this.activeImage(this.indexOfActive);
      if (this.images.length === this.indexOfActive) {
        this.indexOfActive = 0;
        this.property.oneimageurl = this.images[this.indexOfActive];
      } else {
        this.indexOfActive;
      }
    },
    scroll: function(e) {
      const el2 = document.getElementsByClassName("over");
      const el3 = document.getElementsByClassName("fame");
      const el4 = document.getElementsByClassName("nb");
      const el5 = document.getElementsByClassName("sp");

      if (e === 1) {
        const element = document.getElementById("overview2");
        element.scrollIntoView({ behavior: "smooth" });
        const yOffset = -100;
        // const element = document.getElementById(id);
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
        el2[0].classList.add("active");
        el3[0].classList.remove("active");
        el4[0].classList.remove("active");
        el5[0].classList.remove("active");
      } else if (e === 2) {
        const element = document.getElementById("features");
        element.scrollIntoView({ behavior: "smooth" });
        el2[0].classList.remove("active");
        el4[0].classList.remove("active");
        el5[0].classList.remove("active");
        el3[0].classList.add("active");
      } else if (e === 3) {
        // const element = document.getElementById("");
        // element.scrollIntoView({ behavior: "smooth" });
        el2[0].classList.remove("active");
        el4[0].classList.add("active");
        el5[0].classList.remove("active");
        el3[0].classList.remove("active");
      } else if (e === 4) {
        const element = document.getElementById("similar");
        // element.scrollIntoView({ behavior: "smooth" });

        const yOffset = -100;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });

        el2[0].classList.remove("active");
        el4[0].classList.remove("active");
        el5[0].classList.add("active");
        el3[0].classList.remove("active");
      }
    },
    // updateScroll() {
    //   this.scrollPosition = window.scrollY;
    // },
    moveTo: function(id) {
      this.$router
        .push({
          path: `/dashboard/rent/${id}`
        })
        .catch(() => {});
    },

    fetchProperty: async function() {
      this.loader = true;
      const id = this.$route.params.id;
      try {
        // await Api.getRequest(`fetchpropertybyid/${id}`).then((res) => {
        await Api.getRequest(`searchpropertybypin/${id}`)
          .then(res => {
            this.property = res.data.property;
            this.oneproprtyimg = res.data.property.oneimageurl;
            this.images = res.data.property.images_with_details;
            this.facilities = JSON.parse(res.data.property.facilities);
            this.loader = false;
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    fetchProperty2: async function(pin) {
      this.loader = true;
      try {
        await Api.getRequest(`searchpropertybypin/${pin}`)
          .then(res => {
            this.property = res.data.property;
            this.facilities = JSON.parse(res.data.property.facilities);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            this.loader = false;
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },

    nearByProperty: async function() {
      const id = JSON.parse(localStorage.getItem("shelta-user")).id;
      try {
        await Api.getRequest(`fetchnearbypropertiesbyuserid/${id}`)
          .then(res => {
            this.nearbyprops = res.data.properties;
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background: $secondary;
}

.property__holder {
  padding-top: 10rem;
}
.propertyimage_holder {
  padding-bottom: 2rem;
  // .row {
  //   margin-left: 15px !important;
  //   margin-right: 15px !important;
  // }
}

.prop1 {
  // display: flex;
  // justify-content: space-between;
  text-align: left;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    color: $black;
    margin-bottom: 0;
  }
  div {
    img {
      padding-right: 0.5rem;
      padding-left: 0.2rem;
    }
    small {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      margin-bottom: 0;
      line-height: 50px;
      color: rgba(0, 0, 0, 0.5);
    }
    p {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      margin-bottom: 5px;
      // line-height: 50px;
    }
  }
}

// Proprty top details
.prop2 {
  // display: flex;
  // justify-content: space-between;
  text-align: right;
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 32px;
    color: $black;
    margin-bottom: 0;
  }
  div {
    small {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 50px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  // p {
  //   font-family: Gotham;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 20px;
  //   line-height: 50px;
  //   color: rgba(0, 0, 0, 0.5);
  // }
  // div {
  //   display: flex;
  //   img {
  //     padding-right: 0.7rem;
  //   }
  // }
}
// Proprty top details ends

// Image thumbnail
.main__img {
  position: relative;
  text-align: left;
  // width: 100% !important;
  // max-width: 726px;
  // height: 422px !important;
  // max-height: 422px;
  .imgvid {
    position: relative;
    .photo_span {
      cursor: pointer;
      // background: rgba(235, 0, 51, 0.97);
      color: $black;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 33px;
      padding: 5px 10px;
      border-radius: 10px 10px 0 0;
      font-size: 15px;
      margin-right: 10px;
    }
    .video_span {
      cursor: pointer;
      // background: rgba(235, 0, 51, 0.97);
      color: $black;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 33px;
      padding: 5px 10px;
      border-radius: 10px 10px 0 0;
      font-size: 15px;
      // margin-right: 10px;
    }
  }
  .spanDive {
    background: rgba(235, 0, 51, 0.97) !important;
    color: $secondary !important;
  }
  .bigimage {
    width: 100%;
    max-width: 726px;
    // height: auto;
    max-height: 422px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2px;
    // cursor: pointer;
    // padding: 5px;
    // border: 1px solid #ddd;
    // height: 100%;
    object-fit: cover;
  }
}
.videoview {
  width: 100%;
  max-width: 726px;
  height: auto;
  max-height: 422px;
  // display: none;
}
.thumb-wrap {
  position: relative;
  p {
    position: absolute;
    bottom: -14px;
    color: $secondary;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.76);
    width: 96%;
    margin-left: 4px;
    margin-bottom: 20px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    padding-top: 3px;
    opacity: 0.7;
  }
}
.loader-div {
  text-align: center;
}
.first__thumbnail {
  // padding-top: 3.0rem;
  margin-bottom: 15px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.first__thumbnail::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.thumbnail__img {
  // display: flex;
  // justify-content: flex-start;
  // position: relative;
  img:first-child {
    margin-left: 0;
  }
  img {
    width: 100%;
    min-width: 150px;
    margin: 3px;
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px; /* Rounded border */
    padding: 2px; /* Some padding */
    height: 100%;
    max-height: 100px;
    cursor: pointer;
    object-fit: cover;
    // height: 212px;
  }
  img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
.activeSmallImage {
  border: 2px solid $primary !important;
}
// Image thumbnail

// Overview Button
.overview {
  padding-bottom: 4rem;
}
.overviewbtn {
  text-align: left;
  padding-bottom: 2rem;
}
.overviewbtn__navigation {
  // button:first-child {
  //   margin: 0 30px 0 0;
  //   background: $primary;
  //   color: $secondary;
  //   border-radius: 50px;
  //   font-family: Gotham;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 16px;
  //   // line-height: 50px;
  // }
  button {
    margin: 0 20px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    box-shadow: none;
    outline: 0;
    // line-height: 50px;
  }
}

.active {
  margin: 0 30px 0 0;
  background: $primary;
  color: $secondary;
  border-radius: 50px;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
// Overview BUtton

// Overview body

.overview__text {
  text-align: left;
  padding-left: 10px;
  font-family: Gotham-bold;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: -0.05em;
  color: $black;
}
.overview__body {
  background: #ffffff;
  box-shadow: 0px 4px 30px 1px rgba(0, 0, 0, 0.05);
  padding: 17px;
}

.facility__holder {
  display: flex;
  justify-content: space-around;
  padding-top: 0.7rem;
}

.facility__wrap > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 9px;
  color: $black;
  margin-bottom: 0;
  padding-top: 10px;
  // padding: 0 0.1rem;
}

.facility__description {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.8);
  text-align: justify;
  padding-top: 2rem;
}
// Overview body

// Interested in property
.interested__property {
  text-align: start;
  background: #ffffff;
  margin-top: 2.6rem;
  box-shadow: 0px 4px 30px 1px rgba(0, 0, 0, 0.05);
  p {
    background: $black;
    padding: 5px 10px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: -0.05em;
    color: $secondary;
  }
}

.interested_button {
  padding: 0 20px;
}
.rent-link {
  text-decoration: none;
}
.rent_now {
  padding-bottom: 1rem;
  button {
    height: 45px;
    background: $primary;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.05em;
    color: $secondary;
    box-shadow: none;
    outline: 0;
  }
}

.schedule__tour {
  padding-bottom: 2rem;
  button {
    background: #ffffff;
    box-shadow: 0px 4px 30px 1px rgba(0, 0, 0, 0.05);
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    border-radius: 5px;
  }
}

.book__radiobtn {
  div:first-child {
    padding-bottom: 0.6rem;
  }
}
.form__details {
  border: 0.5px solid #6c6c6c;
  box-sizing: border-box;
  padding: 0rem 10px;
  margin-top: 2rem;
}

.book__radiobtn {
  padding: 0 10px 35px 10px;
  div {
    label {
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: -0.05em;
      color: $black;
    }
  }
}

.form__input {
  input {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 5px 10px;
    outline: none;
    border-radius: 0;
    margin: 20px 0px;
  }
}

.form__datetime {
  input {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 5px 10px;
    outline: none;
    border-radius: 0;
    margin: 20px 0px;
    box-shadow: none;
  }
  textarea {
    border: none;
    border-bottom: 1px solid #c4c4c4;
    padding: 5px 10px;
    outline: none;
    border-radius: 0;
    margin: 20px 0px;
    box-shadow: none;
    width: 100%;
  }
}

.interestedBtn {
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.05em;
    width: 100%;
    margin-top: 1rem;
  }
}

// Interested in property

// Features and amenities

.features {
  background: #ffffff;
  box-shadow: 0px 4px 30px 1px rgba(0, 0, 0, 0.05);
  padding-top: 1rem;
}

.features__amenities {
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  padding: 0 30px;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    color: $black;
    text-align: start;
    display: flex;
    span {
      background-color: $primary;
      margin-right: 1rem;
      height: 10px;
      width: 10px;
      min-width: 10px;
      border-radius: 50%;
    }
  }
}

.amenities__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: -0.05em;
  color: $black;
  text-align: start;
  padding-left: 26px;
  padding-top: 4.7rem;
}
// Features and amenities

// Similar properties
.similar {
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 33px;
    letter-spacing: -0.05em;
    color: rgba(0, 0, 0, 0.8);
  }
}
// Similar properties

// Property list grid
.property-list {
  padding: 5rem 0 2rem 0;
}

.no_property {
  font-family: Gotham;
  font-size: 20px;
  font-weight: normal;
  color: $black;
  padding-top: 3rem;
}

.list-img {
  position: relative;
  width: 100%;
  margin-bottom: 15rem;
}
.for-rent {
  margin: auto;
  top: -4%;
  left: 36%;
  position: absolute;
  width: 26%;
  z-index: 999;
}
.for-rent > p {
  background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
  color: $secondary;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  // width: 103px;
  // height: 24px;
  font-size: 12px;
  line-height: 23px;
}

.prop__img {
  width: 417px !important;
  height: 266px !important;
}

.property-details-holder {
  position: absolute;
  top: 92%;
  left: 4%;
  width: 92%;
}
.property-details-wrap {
  background: #ffffff;
  box-shadow: 0px 10px 30px #e2e2e2;
  border-radius: 5px 30px;
}
.details-header {
  // padding-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.details-header > p:first-child {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: $black;
  margin-bottom: 0;
  text-align: left;
}
.details-header > p:nth-child(2) {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: $black;
  margin-bottom: 0;
  cursor: pointer;
  span {
    font-weight: bold;
  }
}

.location-holder {
  display: flex;
  padding: 0.5rem 0 0.6rem 0;
}
.location-holder > img {
  width: 0.7rem;
}
.location-holder > small {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  padding-left: 0.5rem;
  line-height: 11px;
  color: rgba(0, 0, 0, 0.25);
}

.details-text {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: $black;
  text-align: left;
  padding-top: 0.2rem;
}

.property-amount {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 13px;
  text-align: left;
  color: $black;
  padding-top: 0.7rem;
}
.duration {
  font-size: 16px;
}
.facility-holder {
  display: flex;
  justify-content: space-between;
  padding-top: 0.7rem;
}
.facility-wrap {
  // margin: auto;
}
.facility-wrap > p {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 9px;
  color: $black;
  margin-bottom: 0;
  // padding: 0 0.1rem;
}

.view-more {
  padding-bottom: 5rem;
  //  width: 214px;
  //  height: 61px;
}
.view-more > button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: $primary;
  border: none;
  color: $secondary;
  font-family: Gotham;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  // width: 142px;
  // height: 17px;
}
.more_props {
  color: #ffffff;
  text-decoration: none;
}

// Property list

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.7);
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.7);
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 599px) {
  // .list-header > h3 {
  //   font-size: 22px;
  // }
  .property__holder {
    padding-top: 9rem;
  }
  .property-list {
    padding: 3rem 0 2rem 0;
  }
  .facility-wrap > p {
    font-size: 10px;
    line-height: 14px;
  }
  .facility__wrap > p {
    font-size: 10px;
    line-height: 7px;
  }
  .facility__description {
    font-size: 13px;
  }
  .prop1 {
    // display: block;
    h3 {
      font-size: 24px;
      line-height: 26px;
      text-align: left;
    }
    div {
      padding-bottom: 1rem;
      small {
        font-size: 15px;
      }
    }
    img {
      width: 1.4rem;
    }
  }
  .prop2 {
    // display: block;
    text-align: left;
    h3 {
      font-size: 36px;
      line-height: 24px;
    }
    div {
      small {
        font-size: 16px;
      }
    }
  }
  .image-holder {
    position: relative;
  }
  .thumbnail-cover {
    position: relative;
    // bottom: 28px;
  }
  .thumbnail__img {
    img {
      // max-width: 130px;
      min-width: 130px;
    }
  }

  .thumb-wrap {
    p {
      bottom: -15px;
    }
  }

  .main__img .bigimage {
    width: 100%;
    max-height: 100%;
    height: 217px;
    max-width: 328px;
    object-fit: contain;
    // padding-bottom: 0.7rem;
  }
  .videoview {
    // width: 100%;
    // max-width: 726px;
    // height: auto;
    // max-height: 422px;
  }

  .thumbnail-wrap {
    display: flex;
    .first__thumbnail {
      // margin: 0 10px;
    }
    .second__thumbnail {
      margin: 0 10px;
    }
  }
  .overviewbtn__navigation {
    button:first-child {
      margin: 0 30px 0 0;
    }
    button {
      margin: 0 30px 0 0;
    }
  }
  .features__amenities p {
    span {
      display: none;
    }
  }
  .features__amenities {
    padding: 0px 8px;
    p {
      line-height: 20px;
      text-align: left;
      padding: 2px 5px;

      background: rgba(242, 245, 254, 0.8);
      margin: 4px;
      font-size: 13px;
    }
  }
}
</style>
